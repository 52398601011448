import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { setError as globalSetError } from '@/store/setError';

const {
  getVehicleModel,
  postVehicleModel,
  putVehicleModel,
  deleteVehicleModel,
} = backOffice.vehicleModels;

export const scopes = {
  detail: 'detail',
  newModel: 'newModel',
  deleteModel: 'deleteModel',
};

const vehicleModels = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  state: {
    [scopes.detail]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === scopes.deleteModel) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    resetData(state) {
      state[scopes.newModel].data = null;
    },
    clearErrors(state) {
      state[scopes.newModel].error = null;
      state[scopes.newModel].STATUS = { ...INITIAL_STATUS };
    },
  },
  actions: {
    async putVehicleModel({ commit }, { vehicleModelUuid, data }) {
      await runAsyncFlow(commit, {
        request: putVehicleModel,
        params: [vehicleModelUuid, data],
        scope: scopes.newModel,
      });
    },
    async postVehicleModel({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postVehicleModel,
        params: [data],
        scope: scopes.newModel,
      });
    },
    async getVehicleModel({ commit }, data) {
      await runAsyncFlow(commit, {
        request: getVehicleModel,
        params: [data],
        scope: scopes.detail,
      });
    },
    async deleteVehicleModel({ commit }, vehicleModelUuid) {
      await runAsyncFlow(commit, {
        request: deleteVehicleModel,
        params: [vehicleModelUuid],
        scope: scopes.deleteModel,
      });
    },
  },
})));

export default vehicleModels;
