import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  postVehicleBrand,
  putVehicleBrand,
  deleteVehicleBrand,
} = backOffice.vehicleBrands;

export const scopes = {
  vehicleBrand: 'vehicleBrand',
  deleteBrand: 'deleteBrand',
};

const vehicleBrands = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [scopes.deleteBrand]);
    },
    clearErrors,
  },
  actions: {
    async putVehicleBrand({ commit }, { brandId, data }) {
      await runAsyncFlow(commit, {
        request: putVehicleBrand,
        params: [brandId, data],
        scope: scopes.vehicleBrand,
      });
    },
    async postVehicleBrand({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postVehicleBrand,
        params: [data],
        scope: scopes.vehicleBrand,
      });
    },
    async deleteVehicleBrand({ commit }, brandId) {
      await runAsyncFlow(commit, {
        request: deleteVehicleBrand,
        params: [brandId],
        scope: scopes.deleteBrand,
      });
    },
  },
})));

export default vehicleBrands;
