import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  postVehicleCategory,
  putVehicleCategory,
  deleteVehicleCategory,
  postUpdateTariffs,
} = backOffice.vehicleCategory;

const { getOperatorsConfig } = backOffice.userCompany;

export const scopes = {
  vehicleCategory: 'vehicleCategory',
  tariffs: 'tariffs',
  deleteCategory: 'deleteCategory',
  operatorConfig: 'operatorConfig',
};

const vehicleCategories = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [scopes.deleteCategory]);
    },
    clearErrors,
  },
  actions: {
    async getOperatorConfig({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: getOperatorsConfig,
        params: [operatorUuid],
        scope: scopes.operatorConfig,
      });
    },
    async putVehicleCategory({ commit }, { categoryId, data }) {
      await runAsyncFlow(commit, {
        request: putVehicleCategory,
        params: [categoryId, data],
        scope: scopes.vehicleCategory,
      });
    },
    async postUpdateTariffs({ commit }, { categoryId, data }) {
      await runAsyncFlow(commit, {
        request: postUpdateTariffs,
        params: [categoryId, data],
        scope: scopes.tariffs,
      });
    },
    async postVehicleCategory({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postVehicleCategory,
        params: [data],
        scope: scopes.vehicleCategory,
      });
    },
    async deleteVehicleCategory({ commit }, categoryId) {
      await runAsyncFlow(commit, {
        request: deleteVehicleCategory,
        params: [categoryId],
        scope: scopes.deleteCategory,
      });
    },
  },
})));

export default vehicleCategories;
